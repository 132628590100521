import React from "react"
import { Box } from "@chakra-ui/react"
import NewDomain from "../components/shared/NewDomain"

const index = () => {
  return (
    <Box w="100%" h="100%">
      {/* <Hero /> */}
      <NewDomain />
    </Box>
  )
}

export default index
