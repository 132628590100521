import { Box, Button, Text, Stack } from "@chakra-ui/react"

import React, { FunctionComponent } from "react"
import { useColor } from "../../shared/hooks/use-color.hook"

const NewDomain: FunctionComponent = () => {
  const colors = useColor()
  return (
    <Stack
      p={{ base: 5, md: 10 }}
      direction={{ base: "column", md: "row" }}
      style={{
        position: "relative",
        minHeight: "100vh",
        backgroundImage: `url('https://images.photowall.com/products/49950/bundle-of-balloons.jpg?h=699&q=85')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        style={{
          backgroundColor: "rgba(245, 239, 244, 0.3)",
          backdropFilter: "blur(3px)",
        }}
      />
      <Box
        zIndex={10}
        p={5}
        color="black"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        w={"100%"}
      >
        <Text mt={180} fontSize="xl" fontWeight="semibold">
          Creator
        </Text>
        <Text my={25} fontSize="5xl" fontWeight="bold">
          Create Events. Bridge Connections. Map Visualization.
        </Text>
        <Text fontSize="xl" fontWeight="semibold">
          We have changed our domain. Please
          <Button
            size={"md"}
            variant={"ghost"}
            onClick={() => {
              window.open("https://balloon-events.com/create", "_blank")
            }}
            mx={3}
            bgColor={colors.primaryColor}
            textColor={colors.whiteBlackColor}
          >
            click
          </Button>
          and visit our new website.
        </Text>
      </Box>
    </Stack>
  )
}

export default NewDomain
